import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import store from "../store/index.js";

const routes = [
	{
		path: "/",
		redirect: "/client",
	},

	{
		path: "/login",
		name: "Login",
		component: () => import("../views/Login.vue"),
	},
	{
		path: "/user-profile",
		name: "UserProfile",
		meta: {
			id: 0,
			requiresAuth: true,
		},
		component: () => import("../views/UserProfile.vue"),
	},
	{
		path: "/users",
		name: "Users",
		meta: {
			id: 1,
			requiresAuth: true,
		},
		component: () => import("../views/Users.vue"),
	},
	{
		path: "/client",
		name: "Client",
		meta: {
			id: 2,
			requiresAuth: true,
		},
		component: () => import("../views/Client/Index.vue"),
	},
	{
		path: "/client-detail",
		name: "ClientDetail",
		meta: {
			id: 2,
			requiresAuth: true,
		},
		component: () => import("../views/Client/Detail.vue"),
	},
	{
		path: "/client-lesson",
		name: "ClientLesson",
		meta: {
			id: 2,
			requiresAuth: true,
		},
		component: () => import("../views/Client/Lesson.vue"),
	},
	{
		path: "/client-report",
		name: "ClientReport",
		meta: {
			id: 2,
			requiresAuth: true,
		},
		component: () => import("../views/Client/Report.vue"),
	},
	{
		path: "/client-health-report",
		name: "ClientHealthReport",
		meta: {
			id: 2,
			requiresAuth: true,
		},
		component: () => import("../views/Client/HealthReport.vue"),
	},
	{
		path: "/statistical",
		name: "Statistical",
		meta: {
			id: 3,
			requiresAuth: true,
		},
		component: () => import("../views/Statistical.vue"),
	},
	{
		path: "/lesson",
		name: "Lesson",
		meta: {
			id: 4,
			requiresAuth: true,
		},
		component: () => import("../views/Lesson.vue"),
	},
	{
		path: "/template",
		name: "Template",
		meta: {
			id: 5,
			requiresAuth: true,
		},
		component: () => import("../views/Template.vue"),
	},
	{
		path: "/template/sort",
		name: "TemplateSort",
		meta: {
			id: 5,
			requiresAuth: true,
		},
		component: () => import("../views/TemplateSort.vue"),
	},
	{
		path: "/template-build",
		name: "TemplateBuild",
		meta: {
			id: 5,
			requiresAuth: true,
		},
		component: () => import("../views/TemplateBuild.vue"),
	},
	{
		path: "/index",
		name: "Index",
		meta: {
			id: 6,
			requiresAuth: true,
		},
		component: () => import("../views/Index.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from) => {
	const token = Cookies.get("token");
	const hasToken = token && token.length > 0;
	let userAccessMenus = [];
	if (to.meta.requiresAuth) {
		if (!hasToken && to.name !== "Login") {
			return { name: "Login" };
		} else if (hasToken > 0 && !store.state.isLogin) {
			await store.dispatch("getUser");
			try {
				userAccessMenus = store.getters.userAccessMenus;
			} catch {
				userAccessMenus = [];
			}
		} else {
			userAccessMenus = store.getters.userAccessMenus;
		}

		if (!userAccessMenus.find((item) => item.id === to.meta.id) && to.meta.id !== 0) {
			return { name: "Client" };
		}
	} else {
		if (hasToken && to.name === "Login") {
			return { name: "Client" };
		} else {
			return true;
		}
	}
});

export default router;
