<template>
	<div v-if="pageLoading" class="page-loading">
		<div class="spinner-border text-light me-2" role="status"></div>
		<span class="text-light">Loading...</span>
	</div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
	name: "PageLoading",
	setup() {
		const store = useStore();
		const pageLoading = computed(() => store.state.pageLoading);

		return {
			pageLoading,
		};
	},
};
</script>

<style lang="scss" scoped>
.page-loading {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
