<template>
	<Navbar />

	<div class="page-content p-4">
		<router-view />
	</div>

	<PageLoading />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import PageLoading from "@/components/PageLoading.vue";

export default {
	components: {
		Navbar,
		PageLoading,
	},
	setup() {
		return {};
	},
};
</script>

<style lang="scss">
.page-content {
	margin-top: 56px;
	margin-left: 200px;
}
</style>
