import "./assets/scss/main.scss";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vueform from "@vueform/vueform/plugin";
import vueformConfig from "./../vueform.config";

import Pagination from "v-pagination-3";

import "bootstrap";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faTrashCan, faAdd, faPen, faClockRotateLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faBars, faTrashCan, faAdd, faPen, faClockRotateLeft, faXmark);

const app = createApp(App);

app.use(Vueform, vueformConfig);
app.use(router);
app.use(store);
app.component("Pagination", Pagination);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
