import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

const apiRequest = axios.create({
	baseURL: process.env.VUE_APP_AXIOS_BASEURL,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});

const handleCatch = (error) => {
	const status = error.response.status;

	if (status == 401) {
		Swal.fire({
			icon: "warning",
			text: "請重新登入",
		}).then(() => {
			Cookies.remove("token");
			window.location.reload();
		});
	} else {
		console.error(error);
		Swal.fire({
			icon: "error",
			text: "API ERROR",
		});
	}
};

export const apiLogin = ({ account, password }) => {
	/*
		apiLogin 
		* post: 登入
	*/
	return new Promise((resolve, reject) => {
		apiRequest({
			method: "post",
			url: "/api/login",
			data: {
				account,
				password,
			},
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiGetUserInfo = () => {
	/*
		apiGetUserInfo 
		* post: 取得帳號資訊
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");
		apiRequest({
			method: "post",
			url: `/api/getUserInfo`,
			headers: { token },
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiGetRoleList = () => {
	/*
		apiGetRoleList 
		* get: 取得可控管角色清單
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");
		apiRequest({
			method: "get",
			url: "/api/getRoleList",
			headers: { token },
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiUsers = ({ method, data = {}, id = "" }) => {
	/*
		apiUsers
		* get: 取得帳號清單
		* get/id: 取得帳號詳細資訊
		* post: 修改帳號資訊
		* put/id: 修改帳號資訊
		* delete/id: 刪除帳號
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		let requestBody = {
			method,
			url: `/api/users/${id}`,
			headers: { token },
			data,
			params: {},
		};

		if (method == "get") {
			requestBody.params = data;
		}

		apiRequest(requestBody)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiUpdateLoginUser = (data) => {
	/*
		apiUpdateLoginUser
		* post: 編輯登入帳號
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		let requestBody = {
			method: "post",
			url: `/api/updateLoginUser`,
			headers: { token },
			data,
		};

		apiRequest(requestBody)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiIndex = ({ method, data = {}, id = "" }) => {
	/*
		apiIndex
		* get: 取得索引清單
		* get/id: 取得目標索引詳細資訊
		* post: 新增索引
		* put/id: 修改索引
		* delete/id: 刪除索引
	*/
	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		let requestBody = {
			method,
			url: `/api/index/${id}`,
			headers: { token },
			data,
			params: {},
		};

		if (method == "get") {
			requestBody.params = data;
		}

		apiRequest(requestBody)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiIndexGetByCode = ({ code }) => {
	/*
		apiIndexGetByCode
		* [get] /{code}/info: 以索引代碼，取得目標索引詳細資訊
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		apiRequest({
			method: "get",
			url: `/api/index/${code}/info`,
			headers: { token },
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiTemplate = ({ method, data = {}, id = "" }) => {
	/*
		apiTemplate
		* [get]: 取得表單範本清單
		* [get] /{id}: 取得表單範本詳細資訊
		* [post]: 新增表單範本
		* [put] /{id}: 修改表單範本
		* [delete] /{id}: 刪除表單範本
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		let requestBody = {
			method,
			url: `/api/template/${id}`,
			headers: { token },
			data,
			params: {},
		};

		if (method == "get") {
			requestBody.params = data;
		}

		apiRequest(requestBody)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiTemplateCopy = ({ id }) => {
	/*
		apiTemplateCopy
		* [get] /{id}/copy: 複製表單範本
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		apiRequest({
			method: "get",
			url: `/api/template/${id}/copy`,
			headers: { token },
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiTemplateChangeStatus = ({ id, status }) => {
	/*
		apiTemplateCopy
		* [post]: 變更表單範本狀態
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		apiRequest({
			method: "post",
			url: `/api/templateChangeStatus`,
			headers: { token },
			data: {
				id,
				status,
			},
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiTemplateChangeOrder = (data) => {
	/*
		apiTemplateChangeOrder
		* [post]:表單範本排序
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");
		const formData = new FormData();
		formData.append("data", JSON.stringify(data));

		apiRequest({
			method: "post",
			url: `/api/save-template-order`,
			headers: {
				token,
				"Content-Type": "application/x-www-form-urlencoded",
			},
			data: formData,
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiClient = ({ method, data = {}, id = "" }) => {
	/*
		apiClient
		* [get]: 取得上課記錄
		* [get] /{id}: 取得客戶詳細資訊
		* [post]: 新增客戶
		* [put] /{id}: 修改客戶
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		let requestBody = {
			method,
			url: `/api/client/${id}`,
			headers: { token },
			data,
			params: {},
		};

		if (method == "get") {
			requestBody.params = data;
		}

		apiRequest(requestBody)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiGetDoctors = () => {
	/*
		apiGetDoctors
		* [get]: 取得引導員清單
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		apiRequest({
			method: "get",
			url: `/api/getDoctors`,
			headers: { token },
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiLesson = ({ method, data = {}, id = "" }) => {
	/*
		apiLesson
		* [get]: 取得客戶清單
		* [get] /{id}: 取得客戶詳細資訊
		* [post]: 新增客戶
		* [put] /{id}: 修改客戶
		* [delete] /{id}: 刪除客戶
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		let requestBody = {
			method,
			url: `/api/lesson/${id}`,
			headers: { token },
			data,
			params: {},
		};

		if (method == "get") {
			requestBody.params = data;
		}

		apiRequest(requestBody)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiLessonChangeStatus = ({ id, status }) => {
	/*
		apiTemplateCopy
		* [post]: 變更課程狀態
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		apiRequest({
			method: "post",
			url: `/api/lessonChangeStatus`,
			headers: { token },
			data: {
				id,
				status,
			},
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiClientLesson = ({ method, data = {}, id = "" }) => {
	/*
		apiClientLesson
		* [get]: 取得上課記錄
		* [get] /{id}: 取得上課記錄詳細資訊
		* [post]: 新增上課記錄
		* [put] /{id}: 修改上課記錄
		* [delete] /{id}: 刪除上課記錄
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");
		let requestBody = {
			method,
			url: `/api/client-class/${id}`,
			headers: { token },
			data,
			params: {},
		};

		if (method == "get") {
			requestBody.params = data;
		}

		apiRequest(requestBody)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiClientLessonChangeOrder = (data) => {
	/*
		apiClientLessonChangeOrder
		* [post]: 上課記錄排序
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");
		const formData = new FormData();
		formData.append("data", JSON.stringify(data));

		apiRequest({
			method: "post",
			url: `/api/save-class-template-order`,
			headers: {
				token,
				"Content-Type": "application/x-www-form-urlencoded",
			},
			data: formData,
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiClassTemplate = ({ method, data = {}, id = "" }) => {
	/*
		apiClassTemplate
		* [get]: 取得 單一上課記錄 所有表單
		* [get] /{id}: 取得 單一上課記錄 表單詳細資訊
		* [post]: 新增 表單至上課記錄
		* [put] /{id}: 編輯 上課記錄內的目標表單
		* [delete] /{id}: 刪除上課記錄表單 (將保留記錄&答案內容)
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		let requestBody = {
			method,
			url: `/api/class-template/${id}`,
			headers: { token },
			data,
			params: {},
		};

		if (method == "get") {
			requestBody.params = data;
		}

		apiRequest(requestBody)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiGetSpecialTemplate = ({ client_id, template_type }) => {
	/*
		apiGetSpecialTemplate
		* [post]: 變更課程狀態

		data:{
			client_id: 客戶 id
			template_type 表單類型
				* 1: 自我五項評分表
				* 2: 承諾表
				* 3: 追蹤表
				* 4: 健康資訊報告
		}
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		apiRequest({
			method: "post",
			url: `/api/get-special-template`,
			headers: { token },
			data: {
				client_id,
				template_type,
			},
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};

export const apiStatistical = ({ start_date, end_date }) => {
	/*
		apiStatistical
		* [post]: 取得統計資料

		data:{
			start_date: 統計起始日
			end_date: 統計結束日
		}
	*/

	return new Promise((resolve, reject) => {
		const token = Cookies.get("token");

		apiRequest({
			method: "post",
			url: `/api/statistical`,
			headers: { token },
			data: {
				start_date,
				end_date,
			},
		})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				handleCatch(error);
				reject();
			});
	});
};
