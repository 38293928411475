import zh_TW from "@vueform/vueform/locales/zh_TW";
import bootstrap from "@vueform/vueform/themes/bootstrap";

export default {
	theme: bootstrap,
	locales: { zh_TW },
	locale: "zh_TW",
	addClasses: {
		StaticElement: {
			content: "white-space-pre-wrap",
		},
		ElementLabel: {
			container: "white-space-pre-wrap align-items-center",
		},
		RadioElement: {
			container: "my-1",
			input: "me-2 mt-0",
		},
		RadiogroupRadio: {
			container: "my-1",
			input: "me-2 mt-0",
		},
		CheckboxElement: {
			input: "me-2 mt-0",
		},
		CheckboxgroupCheckbox: {
			container: "my-1",
			input: "me-2 mt-0",
		},
	},
};
