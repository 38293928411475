import { createStore } from "vuex";
import { apiGetUserInfo } from "../assets/js/api.js";
import router from "../router/index";
import Cookies from "js-cookie";

export default createStore({
	state: {
		pageLoading: false,
		isLogin: false,
		userData: {
			account: "",
			name: "",
			note: "",
			roleID: "",
			roleName: "",
		},
		userMenus: [],
		allMenus: [
			{
				id: 2,
				menueName: "客戶資料",
				routerName: "Client",
			},
			{
				id: 3,
				menueName: "統計資料",
				routerName: "Statistical",
			},
			{
				id: 4,
				menueName: "課程名稱管理",
				routerName: "Lesson",
			},
			{
				id: 5,
				menueName: "表單範本管理",
				routerName: "Template",
			},
			{
				id: 6,
				menueName: "索引管理",
				routerName: "Index",
			},
			{
				id: 1,
				menueName: "帳號管理",
				routerName: "Users",
			},
		],

		template: {
			customID: [1, 2, 3, 4],
		},

		client: {
			// 性別選單
			genderList: {
				0: "",
				1: "男",
				2: "女",
			},

			// 會員身份選單
			typeList: {
				0: "",
				1: "會員",
				2: "非會員",
			},

			// 會員分類選單
			classificationList: {
				0: "",
				1: "一般",
				2: "VIP",
				3: "家庭",
				4: "公司行號",
			},
		},
	},
	getters: {
		userAccessMenus(state) {
			let list = [];
			for (let i = 0; i < state.userMenus.length; i++) {
				const navItem = state.allMenus.find((item) => item.id === state.userMenus[i].id);
				if (navItem) {
					list.push(navItem);
				}
			}

			return list;
		},
	},
	mutations: {
		SET_PAGE_LOADING(state, isLoading) {
			state.pageLoading = isLoading;
		},
		SET_IS_LOGIN(state, payload) {
			state.isLogin = payload;
		},
		SET_USER_DATA(state, payload) {
			state.userData.account = payload.account;
			state.userData.name = payload.name;
			state.userData.note = payload.note;
			state.userData.roleID = payload.roleID;
			state.userData.roleName = payload.roleName;

			state.userMenus = payload.menus;
		},
	},
	actions: {
		getUser({ commit, dispatch }) {
			return new Promise((resolve, reject) => {
				const token = Cookies.get("token");

				if (token && token !== undefined) {
					commit("SET_PAGE_LOADING", true);

					apiGetUserInfo()
						.then((response) => {
							commit("SET_PAGE_LOADING", false);

							if (response.data.status === "0") {
								const account = response.data.data.user.account;
								const name = response.data.data.user.name;
								const note = response.data.data.user.note;
								const roleID = response.data.data.user.role_id;
								const roleName = response.data.data.user.role_name;

								const menus = response.data.data.menus;

								commit("SET_IS_LOGIN", true);

								commit("SET_USER_DATA", {
									account,
									name,
									note,
									roleID,
									roleName,
									menus,
								});

								resolve();
							} else {
								dispatch("logout");

								reject();
							}
						})
						.catch((error) => {
							commit("SET_PAGE_LOADING", false);
							dispatch("logout");
							reject();
						});
				}
			});
		},
		logout({ commit }) {
			Cookies.remove("token");
			commit("SET_IS_LOGIN", false);

			commit("SET_USER_DATA", {
				account: "",
				name: "",
				note: "",
				roleID: "",
				roleName: "",
				menus: [],
			});
			router.push({ name: "Login" });
		},
		setPageLoading({ commit }, isLoading) {
			commit("SET_PAGE_LOADING", isLoading);
		},
	},
});
