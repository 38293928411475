<template>
	<div v-if="isLogin">
		<nav class="navbar navbar-expand-lg bg-light fixed-top">
			<div class="container-fluid">
				<a class="navbar-brand text-dark" href="#">啟悅頻衡客戶管理系統</a>
				<div class="d-flex align-items-center">
					<div class="dropdown">
						<a class="dropdown-toggle" role="button" data-bs-toggle="dropdown">
							Hi, <span class="me-2">{{ userData.name }}</span>
						</a>
						<ul class="dropdown-menu">
							<li>
								<router-link :to="{ name: 'UserProfile' }" class="dropdown-item">帳號設定</router-link>
							</li>
							<li><a class="dropdown-item" role="button" @click="logout()">登出</a></li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
		<div class="side-nav bg-primary text">
			<ul class="nav flex-column pt-4">
				<li class="nav-item" v-for="item in userAccessMenus" :key="item.id">
					<router-link class="nav-link py-3" :to="{ name: item.routerName }">{{
						item.menueName
					}}</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
export default {
	name: "navbar",
	setup() {
		const store = useStore();
		const isLogin = computed(() => store.state.isLogin);
		const userData = computed(() => store.state.userData);
		const userAccessMenus = computed(() => store.getters.userAccessMenus);

		const logout = () => store.dispatch("logout");

		return {
			userData,
			isLogin,
			logout,
			userAccessMenus,
		};
	},
};
</script>

<style lang="scss" scoped>
.side-nav {
	width: 200px;
	height: 100%;
	position: fixed;
	left: 0;
	top: 56px;
	z-index: 2;
	.nav-link {
		color: #ffffff;

		&.router-link-active {
			color: $primary;
			background-color: #ffffff;
		}
	}
}

.dropdown-menu {
	right: 0;
	left: auto;
}
</style>
